<template>
    <Dropdown class="form-group date-picker"
              :menu-right="menuRight"
              :class="{
              'has-error': errors && errors.has(field),
              'has-notice': errors && errors.hasNotice(field)
              }">
        <label :for="field" :class="{'active-label': labelClass}" v-if="label">{{ label }} <div v-if="infoIcon" v-tooltip.hover="hoverInfoText" class="small-info-circle">i</div></label>
        <input :id="setInputId"
               :placeholder="placeholder"
               readonly
               class="form-control dropdown-toggle"
               type="text"
               :value="formatedValue"
               @change="inputChanged"
               :disabled="disabled" />
        <AppError :errors="errors" v-if="errors" :field="field"/>
        <template slot="dropdown">
            <li>
                <DatePicker :today-btn="false"
                            :clear-btn="false"
                            icon-control-left="material-icons md-18 keyboard_arrow_left"
                            icon-control-right="material-icons keyboard_arrow_right md-18"
                            v-model="datepickerVal"
                            format="yyyy-MM-dd"
                            :limit-from="limitFrom"
                            :limit-to="limitTo"
                            :disabled="disabled"
                            :week-starts-with="1"
                />
            </li>
        </template>
    </Dropdown>
</template>

<script>
import { mapState } from 'vuex';
import dayjs from 'dayjs';
import Dropdown from 'uiv/src/components/dropdown/Dropdown';
import DatePicker from 'uiv/src/components/datepicker/DatePicker';
import { tooltip } from 'uiv';
import AppError from '../AppError';


import 'dayjs/locale/de';

export default {
  i18nOptions: { namespaces: 'datepicker' },
  components: {
    Dropdown,
    DatePicker,
    AppError,
  },
  directives: {
    tooltip,
  },
  props: ['errors', 'label', 'labelClass', 'value', 'field', 'placeholder', 'menuRight', 'limitFrom', 'limitTo', 'disabled', 'infoIcon', 'hoverInfoText'],
  data() {
    return {
      datepickerVal: this.value,
    };
  },
  watch: {
    value() {
      this.datepickerVal = this.value;
    },
    datepickerVal() {
      this.$emit('input', this.datepickerVal);
      this.$emit('change', this.datepickerVal);
    },
  },
  computed: {
    ...mapState({
      language: state => state.language.lang,
    }),
    // eslint-disable-next-line vue/return-in-computed-property
    formatedValue() {
      if (this.value && this.language && this.language.code) {
        return dayjs(String(this.value), 'YYYY-MM-DD').locale(this.language.code).format('DD MMM YYYY');
      }
    },
    setInputId() {
      return this.dynamicID(this.id) || this.dynamicID(this.field);
    },
  },
  methods: {
    inputChanged(event) {
      this.datepickerVal = event.target.value;
    },
    dateValidation(dateString) {
      if (dateString) {
        const regEx = /^\d{4}-\d{2}-\d{2}$/;
        if (!dateString.match(regEx)) return false; // Invalid format
        const d = new Date(dateString);
        const dNum = d.getTime();
        if (!dNum && dNum !== 0) return false; // NaN value, Invalid date
        return d.toISOString().slice(0, 10) === dateString;
      }
    },
    dynamicID(id) {
      return id + Math.floor(Math.random() * Date.now().toString());
    },
  },
};
</script>
